import { Injectable } from '@angular/core';
import { BackendCalendar } from '../models/calendar';
import { CartoAfRelation, CartoAfClass, Instance } from '../models/class';
import { DmsOptions } from '../models/resource';
import { ScopeCalendars, ScopeClass, ScopeDms, ScopeInstance } from '../models/scope-tab';
import { OptionsTabs } from '../models/tab';
import { ApiService } from './api.service';
import { ApplicationStoreService } from './application-store.service';
import { CartoafTabService } from './cartoaf-tab.service';
import { OldAlertService } from './old-alert.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class RendererService {

  constructor(
    private tabService: CartoafTabService,
    private apiService: ApiService,
    private utils: UtilsService,
    private store: ApplicationStoreService,
    private alertService: OldAlertService
  ) {
    this.checkTabCache()
  }

  renderInTab(tabKey: string, options: OptionsTabs, active: boolean = true) {
    if (this.tabService.hasTab(tabKey)) {
      if(active){
        this.tabService.setActiveTab(tabKey);
      }
    } else {
      this.tabService.push(tabKey, options, active);
    }
  };

  openClass(classId: string | number, active: boolean = true) {

    this.apiService.getClassDetail(classId).subscribe((response: CartoAfClass) => {
      let scope: ScopeClass = {
        class: response
      };
      let tabKey = "class_"+scope.class.class_id;
      this.renderInTab(tabKey, {
        key: tabKey,
        title: this.utils.resolveObjectName(scope.class),
        icon: 'fa-table',
        scope: scope,
        type: 'class'
      }, active);
    })
  };

  openInstance(classId: number | string, instanceId: number | string, relations?: CartoAfRelation, useAsModel: boolean = false, active: boolean = true) {

    let tabKey = "form_"+classId+"."+instanceId;

    if (useAsModel === true) {
      tabKey += "_copy"
    }

    if (this.tabService.hasTab(tabKey)) {
      this.tabService.setActiveTab(tabKey);
    } else {
      this.apiService.getClassDetail(classId).subscribe((response: CartoAfClass) => {
        let classObj = response;
        if (classObj === undefined) {
          this.alertService.showAlert('error', "Si è verificato un errore, la classe che hai cercato non esiste");
        } else if (instanceId != 0) {
          this.apiService.getInstanceDetail(classObj.class_id, instanceId).subscribe((response: Instance) => {
            let instanceObj = response;
            let scope: ScopeInstance = {
              path: [this.store.getApplication()?.app_id, classObj.class_id, instanceObj.__id].join("/"),
              panel: {
                useAsModel: useAsModel,
                class: classObj,
                instance: instanceObj,
                callbackClose: () => {
                  this.tabService.removeTab(tabKey);
                }
              }
            };

            let title = classObj.label+": "+ (response?.[classObj.tab_view] || instanceId);
            if (useAsModel === true) {
              title += " Copia"
              scope.panel.instance.actions = [];
              scope.panel.class.flg_dms = false;
            }

            this.renderInTab(tabKey, {
              key: tabKey,
              title: title,
              icon: 'fa-list',
              scope: scope,
              type: 'instance'
            }, active);
          }, error => {
            this.alertService.showAlert('error', error.error);
            // $scope.contentIsReady = true;
          })
        } else {
          let scope: ScopeInstance = {
            panel: {
              class: classObj,
              instance: {__id: 0},
              callbackClose: () => {
                this.tabService.removeTab(tabKey);
              }
            }
          };

          // Apply relations
          if (relations) {
            scope.panel.instance[relations.propertyname] = relations.parentInstanceId;
          }

          this.renderInTab(tabKey, {
            key: tabKey,
            title: "Aggiungi " + classObj.class_name,
            icon: 'fa-plus',
            scope: scope,
            type: 'instance'
          }, active);
        }
      }, error => {
        this.alertService.showAlert('error', error.error);
        // $scope.contentIsReady = true;
      })
    }
  };

  openDms(path: string | null, options: DmsOptions, active: boolean = true) {
    options = Object.assign({
      app_id: parseInt(this.store.application.dms_path || this.store.application.app_id),
      class_id: null,
      instance_id: null,
      title: 'DMS',
      class_name: null
    }, options);

    let tabKey = `dms_${options.shared ? 'shared_' : ''}${[options.app_id, options.class_id, options.instance_id].join('.')}`;

    let scope: ScopeDms = {
      appId: options.app_id,
      classId: options.class_id,
      instanceId: options.instance_id
    };

    this.apiService.getClassDetail(options.class_id).subscribe((response: CartoAfClass) => {
      // override class
      if (response?.dms_path) {
        options.class_id = response.dms_path;
      }

      if(!options.shared) {
        scope.flgWrite = response?.flg_write;
        scope.flg_write = response?.flg_write;
        scope.allow_insert = response?.allow_insert;
        scope.allow_delete = response?.allow_delete;
        scope.allow_versioning = response?.allow_versioning;
        scope.dms_ro = response?.dms_ro;
      }

      if (path) {
        scope.path = path;
      } else {
        scope.path = options.app_id+"/";
        if (options.class_id) scope.path += options.class_id+"/";
        if (options.instance_id) scope.path += options.instance_id+"/";
      }

      this.renderInTab(tabKey, {
        key: tabKey,
        title: options.title,
        scope: scope,
        icon: 'fa-archive',
        type: 'dms_view_container'
      }, active);
    });
  };

  openCalendar(appId: string | number, calendars: BackendCalendar[]) {
    let tabKey = "calendar_"+appId;
    let scope: ScopeCalendars = {
      calendars: calendars
    };

    this.renderInTab(tabKey, {
      key: tabKey,
      title: "Calendario",
      scope: scope,
      icon: 'fa-calendar',
      type: 'calendar'
    });
  }
  
  checkTabCache() {
    const tabs: string[] = JSON.parse(sessionStorage.getItem('cartoafTabs'));
    const activeTab: string = sessionStorage.getItem('cartoafActiveTab');
    if(tabs) {
      tabs.forEach(tab => {
        const record = tab.split('_');
        switch(record[0]) {
          case 'class':
            this.openClass(record[1], false);
            break;
          case 'form':
            const info = record[1].split('.');
            if(info[1] != '0'){
              this.openInstance(info[0], info[1], null, false, false)
            }
            break;
          case 'dms':
            let options = [];
            let shared: boolean = record[1] == 'shared';
            if(shared) {
              options = record[2].split('.');
            } else {
              options = record[1].split('.');
            }
            this.openDms(null, {
              app_id: options[0], 
              class_id: options[1] != '' ? options[1] : null, 
              instance_id: options[2] != '' ? options[2] : null,
              title: options[2] != '' ? `DMS${shared ? ' condiviso': ''} ${this.utils.resolveObjectName({__id: options[2]})}` : 'DMS',
              shared: shared
            }, false)
            break;
        }
      })
      if(activeTab) {
        this.tabService.setActiveTab(activeTab);
      }
    }
  }

  checkActiveTab(tabKey: string) {
    const activeTab: string = sessionStorage.getItem('cartoafActiveTab');
    return activeTab == tabKey || !activeTab
  }
}
