// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { CartoafEnv } from 'src/app/core/models/env';
import Package from '../../package.json';

export const environment: CartoafEnv = {
  production: false,
  app_version: Package.version,
  // "restServer": "https://geo.portalecomuni.net/devrest/api/cartodue",
  "restServer": "http://5.249.142.111/svilbackend/api/cartoaf",
  "dmsStreamer": "http://5.249.142.103:9000",
  "wsServer": "",
  "WSAutoRetryRequestsNumber": 3,
  "welcome": {
      "background": "welcome.jpg",
      "message": "CARTODUE"
  },
  "footer": {
      "projectLabel": "",
      "projectName": "",
      "projectURL": ""
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
